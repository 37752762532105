<template>
  <section id="check">
    <div class="checkout_wrap">
      <div class="pay_list_wrap">
        <p class="stitle stitle_small">
          {{ $t("payMessage.paymentFinished") }}
        </p>
        <div class="pay_title">
          {{ $t("main.mySettlementHistory") }}
        </div>
        <dl class="pay_list">
          <dt>{{ $t("main.amountPaid") }}</dt>
          <dd class="c_b">{{ $t("common.won", ["227,500"]) }}</dd>
          <dt>{{ $t("main.amountRequiredForAdditionalSettlement") }}</dt>
          <dd class="c_r">{{ $t("common.won", ["0"]) }}</dd>
        </dl>
      </div>
      <div class="ft_btn">
        <a class="btn_basic btn_checkin mg0">
          {{ $t("common.goToApplication", [checkInName]) }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PayOk",
  computed: {
    ...mapGetters(["checkInName"]),
  },
};
</script>
